.skills_box1 .card-body,
.skills_box2 .card-body,
.skills_box3 .card-body {
    flex: 0;
}

@media(min-width: 960px) {
    .skills_box1 {
        top: -4rem;
    }

    .skills_box2 {
        top: -8rem;
    }

    .skills_box3 {
        top: -4rem;
    }
} 