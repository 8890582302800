/* React Slick dots customization */
.slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.slick-dots li button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  text-align: center;
  opacity: 1 !important;
  color: #fff;
  background-color: #fff;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
}

.slick-dots {
  width: 0 !important;
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 11%;
  left: -67%;
}

.slick-dots li {
  display: flex !important;
  align-items: center;
  margin-bottom: 2.25rem !important;
}

.test p {
  margin-bottom: 25px;
  margin-left: 10px;
}

.slick-dots .slick-active li {
  opacity: 1;
}

@media (max-width: 1025px) {
  .slick-dots {
    display: block !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    bottom: -25px !important;
    width: 100% !important;
    text-align: center;
  }

  .slick-dots li {
    position: relative !important;
    display: inline-block !important;
    width: 20px;
    height: 20px;
  }
}

/* React Slick arrow navigations customization */
.slick-slider {
  position: relative;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  width: 50px;
  height: 50px;
  background-color: white;
  border: 2px solid rgb(209 213 219);
  border-radius: 50%;
  transition: border-color 150ms ease-in-out;
}

.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover,
.slick-slider .slick-prev:focus,
.slick-slider .slick-next:focus {
  border: 2px solid rgb(209 213 219);
  background-color: rgb(209 213 219);
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: #000000;
}

.slick-slider .slick-next {
  position: absolute;
  top: 110%;
  right: 0;
}

.slick-slider .slick-prev {
  position: absolute;
  top: 110%;
  left: 80%;
}

@media (max-width: 1025px) {
  .slick-slider .slick-next {
    position: absolute;
    top: 130%;
    left: 60%;
    transform: translateX(-60%);
  }

  .slick-slider .slick-prev {
    position: absolute;
    top: 130%;
    left: 40%;
    transform: translateX(-40%);
  }
}
