/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  /* font-size: $font-med; */
  /* color: $grayishBlue; */
  line-height: 1.3;
  overflow-x: hidden;
}

/* Navbar */
.navbar {
  backdrop-filter: blur(5px);
}

/* Right Menu */
.menu-active {
  top: 0;
}

/* Hero section */
.hero {
  min-height: 85vh;
}

/* About section */
.bg-top {
  background-image: url("./assets/hexagon-top.svg");
}

.bg-bottom {
  background-image: url("./assets/hexagon-bottom.svg");
  /* transform: rotate(180deg); */
}

/* Contact section */
.input-card {
  -webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.6);
}

/* Utility */
.customBtn svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.customBtn svg rect {
  fill: none;
  /* stroke: #333333; */
  stroke-width: 3;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}

.customBtn:hover > a {
  font-weight: 900;
  /* letter-spacing: 1px; */
}

.customBtn:hover rect {
  /* stroke-width: 5; */
  stroke-dasharray: 15, 310;
  stroke-dashoffset: 48;
  transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}
